var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "validation-observer",
        { ref: "liveinfoFormRules" },
        [
          _c(
            "b-card",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-start",
                      attrs: { cols: "12", md: "2" },
                    },
                    [
                      _c("feather-icon", {
                        attrs: { icon: "CalendarIcon", size: "19" },
                      }),
                      _c("h4", { staticClass: "mb-0 ml-50" }, [
                        _vm._v(" 게시기간 "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-1" },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12", md: "3" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "게시기간(Fr)",
                            "label-for": "liveinfo-start-date",
                          },
                        },
                        [
                          _c("validation-provider", {
                            attrs: {
                              name: "게시기간(Fr)",
                              rules: {
                                required: true,
                                before: _vm.liveinfo.endDate,
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-datepicker", {
                                      attrs: {
                                        id: "liveinfo-start-date",
                                        placeholder: "게시기간(Fr)",
                                        state: errors.length > 0 ? false : null,
                                      },
                                      model: {
                                        value: _vm.liveinfo.startDate,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.liveinfo,
                                            "startDate",
                                            $$v
                                          )
                                        },
                                        expression: "liveinfo.startDate",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "12", md: "3" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "게시기간(To)",
                            "label-for": "liveinfo-end-date",
                          },
                        },
                        [
                          _c("validation-provider", {
                            attrs: {
                              name: "게시기간(To)",
                              rules: {
                                required: true,
                                after: _vm.liveinfo.startDate,
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-datepicker", {
                                      attrs: {
                                        id: "liveinfo-end-date",
                                        placeholder: "게시기간(To)",
                                        state: errors.length > 0 ? false : null,
                                      },
                                      model: {
                                        value: _vm.liveinfo.endDate,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.liveinfo, "endDate", $$v)
                                        },
                                        expression: "liveinfo.endDate",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-card",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-start",
                      attrs: { cols: "12", md: "8" },
                    },
                    [
                      _c("feather-icon", {
                        attrs: { icon: "UserCheckIcon", size: "19" },
                      }),
                      _c(
                        "h4",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.contentType != 2,
                              expression: "contentType != 2",
                            },
                          ],
                          staticClass: "mb-0 ml-50",
                        },
                        [_vm._v(" 모아보기/라이브DI/팝업 알림 노출 조건 ")]
                      ),
                      _c(
                        "h4",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.contentType == 2,
                              expression: "contentType == 2",
                            },
                          ],
                          staticClass: "mb-0 ml-50",
                        },
                        [_vm._v(" 팝업 알림 노출 조건 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-1" },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "10" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "진료과",
                            "label-for": "liveinfo-cond-dept",
                          },
                        },
                        [
                          _c("validation-provider", {
                            attrs: { name: "진료과", rules: "required" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "liveinfo-cond-dept",
                                        readonly: "",
                                        state: errors.length > 0 ? false : null,
                                      },
                                      model: {
                                        value: _vm.liveinfo.condDeptNames,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.liveinfo,
                                            "condDeptNames",
                                            $$v
                                          )
                                        },
                                        expression: "liveinfo.condDeptNames",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mb-50", attrs: { cols: "2" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mt-0 mt-md-2",
                          attrs: { variant: "outline-primary" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.showMediDeptSelect = true
                            },
                          },
                        },
                        [
                          _c("feather-icon", {
                            staticClass: "mr-50",
                            attrs: { icon: "PlusSquareIcon" },
                          }),
                          _c("span", [_vm._v("목록")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.contentType == 1
                ? _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { cols: "12" } }, [
                        _c("label", [
                          _vm._v("진료과 세팅 정보로 닥터인포에 노출하기"),
                        ]),
                      ]),
                      _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c(
                                "b-form-radio-group",
                                {
                                  model: {
                                    value: _vm.liveinfo.isCondDeptDrInfo,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.liveinfo,
                                        "isCondDeptDrInfo",
                                        $$v
                                      )
                                    },
                                    expression: "liveinfo.isCondDeptDrInfo",
                                  },
                                },
                                [
                                  _c(
                                    "b-form-radio",
                                    {
                                      staticClass: "custom-control-primary",
                                      attrs: { value: "true" },
                                    },
                                    [_vm._v(" 사용 ")]
                                  ),
                                  _c(
                                    "b-form-radio",
                                    {
                                      staticClass: "custom-control-secondary",
                                      attrs: { value: "false" },
                                    },
                                    [_vm._v(" 사용안함 ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "10" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "전공과",
                            "label-for": "liveinfo-cond-major",
                          },
                        },
                        [
                          _c("validation-provider", {
                            attrs: { name: "전공과", rules: "required" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "liveinfo-cond-major",
                                        readonly: "",
                                        state: errors.length > 0 ? false : null,
                                      },
                                      model: {
                                        value: _vm.liveinfo.condMajorNames,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.liveinfo,
                                            "condMajorNames",
                                            $$v
                                          )
                                        },
                                        expression: "liveinfo.condMajorNames",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mb-50", attrs: { cols: "2" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mt-0 mt-md-2",
                          attrs: { variant: "outline-primary" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.showMajorSelect = true
                            },
                          },
                        },
                        [
                          _c("feather-icon", {
                            staticClass: "mr-50",
                            attrs: { icon: "PlusSquareIcon" },
                          }),
                          _c("span", [_vm._v("목록")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "10" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "지역",
                            "label-for": "liveinfo-cond-region",
                          },
                        },
                        [
                          _c("validation-provider", {
                            attrs: { name: "지역", rules: "required" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "liveinfo-cond-region",
                                        readonly: "",
                                        state: errors.length > 0 ? false : null,
                                      },
                                      model: {
                                        value: _vm.liveinfo.condRegionNames,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.liveinfo,
                                            "condRegionNames",
                                            $$v
                                          )
                                        },
                                        expression: "liveinfo.condRegionNames",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mb-50", attrs: { cols: "2" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mt-0 mt-md-2",
                          attrs: { variant: "outline-primary" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.showRegionSelect = true
                            },
                          },
                        },
                        [
                          _c("feather-icon", {
                            staticClass: "mr-50",
                            attrs: { icon: "PlusSquareIcon" },
                          }),
                          _c("span", [_vm._v("목록")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { cols: "12" } }, [
                    _c("label", [_vm._v("연령")]),
                  ]),
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-start",
                      attrs: { cols: "12", lg: "6" },
                    },
                    [
                      _c("validation-provider", {
                        attrs: { name: "시작나이", rules: "required" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var errors = ref.errors
                              return [
                                _c("b-form-input", {
                                  on: { keyup: _vm.restrictToNumbers },
                                  model: {
                                    value: _vm.liveinfo.minAge,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.liveinfo, "minAge", $$v)
                                    },
                                    expression: "liveinfo.minAge",
                                  },
                                }),
                                _c("small", { staticClass: "text-danger" }, [
                                  _vm._v(_vm._s(errors[0])),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("label", { staticClass: "ml-50 mr-50" }, [
                        _vm._v("~"),
                      ]),
                      _c("validation-provider", {
                        attrs: { name: "끝나이", rules: "required" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var errors = ref.errors
                              return [
                                _c("b-form-input", {
                                  on: { keyup: _vm.restrictToNumbers },
                                  model: {
                                    value: _vm.liveinfo.maxAge,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.liveinfo, "maxAge", $$v)
                                    },
                                    expression: "liveinfo.maxAge",
                                  },
                                }),
                                _c("small", { staticClass: "text-danger" }, [
                                  _vm._v(_vm._s(errors[0])),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("p"),
              _c("p", [
                _vm._v(
                  "* 처방 팝업 및 스케줄 팝업을 사용하지 않을 경우, 팝업 알림 노출 조건을 임의의 값(ex. 전체 진료과)으로 설정해주세요"
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-card",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.contentType != 2,
              expression: "contentType != 2",
            },
          ],
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-start",
                  attrs: { cols: "12", md: "6" },
                },
                [
                  _c("feather-icon", {
                    attrs: { icon: "GridIcon", size: "19" },
                  }),
                  _c(
                    "h4",
                    {
                      staticClass: "mb-0 ml-50",
                      staticStyle: { "min-width": "120px" },
                    },
                    [_vm._v(" 모아보기 ")]
                  ),
                  _c(
                    "b-form-checkbox",
                    {
                      staticClass: "custom-control-info",
                      attrs: { switch: "", inline: "" },
                      model: {
                        value: _vm.liveinfo.isMoaView,
                        callback: function ($$v) {
                          _vm.$set(_vm.liveinfo, "isMoaView", $$v)
                        },
                        expression: "liveinfo.isMoaView",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.liveinfo.isMoaView ? "사용" : "사용안함") +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "validation-observer",
            { ref: "liveinfoMoaRules" },
            [
              _c(
                "b-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.liveinfo.isMoaView,
                      expression: "liveinfo.isMoaView",
                    },
                  ],
                  staticClass: "mt-1",
                },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "게시기간(Fr)",
                            "label-for": "liveinfo-moa-start-date",
                          },
                        },
                        [
                          _c("validation-provider", {
                            attrs: {
                              name: "게시기간(Fr)",
                              rules: {
                                required: true,
                                before: _vm.liveinfo.moaEndDate,
                                dateBetween: [
                                  _vm.liveinfo.startDate,
                                  _vm.liveinfo.endDate,
                                ],
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  var failedRules = ref.failedRules
                                  return [
                                    _c("b-form-datepicker", {
                                      attrs: {
                                        id: "liveinfo-moa-start-date",
                                        placeholder: "게시기간(Fr)",
                                        state: errors.length > 0 ? false : null,
                                      },
                                      model: {
                                        value: _vm.liveinfo.moaStartDate,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.liveinfo,
                                            "moaStartDate",
                                            $$v
                                          )
                                        },
                                        expression: "liveinfo.moaStartDate",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              failedRules.hasOwnProperty(
                                                "dateBetween"
                                              )
                                                ? "모아보기 기간은 게시기간 이내로 제한됩니다."
                                                : errors[0]
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "게시기간(To)",
                            "label-for": "liveinfo-moa-end-date",
                          },
                        },
                        [
                          _c("validation-provider", {
                            attrs: {
                              name: "게시기간(To)",
                              rules: {
                                required: true,
                                after: _vm.liveinfo.moaStartDate,
                                dateBetween: [
                                  _vm.liveinfo.startDate,
                                  _vm.liveinfo.endDate,
                                ],
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  var failedRules = ref.failedRules
                                  return [
                                    _c("b-form-datepicker", {
                                      attrs: {
                                        id: "liveinfo-moa-end-date",
                                        placeholder: "게시기간(To)",
                                        state: errors.length > 0 ? false : null,
                                      },
                                      model: {
                                        value: _vm.liveinfo.moaEndDate,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.liveinfo,
                                            "moaEndDate",
                                            $$v
                                          )
                                        },
                                        expression: "liveinfo.moaEndDate",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              failedRules.hasOwnProperty(
                                                "dateBetween"
                                              )
                                                ? "모아보기 기간은 게시기간 이내로 제한됩니다."
                                                : errors[0]
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "12", md: "12" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "상단 고정",
                            "label-for": "liveinfo-is-top-fix",
                          },
                        },
                        [
                          _c(
                            "b-form-radio-group",
                            {
                              on: { change: _vm.isTopFixChange },
                              model: {
                                value: _vm.liveinfo.isTopFix,
                                callback: function ($$v) {
                                  _vm.$set(_vm.liveinfo, "isTopFix", $$v)
                                },
                                expression: "liveinfo.isTopFix",
                              },
                            },
                            [
                              _c(
                                "b-form-radio",
                                {
                                  staticClass: "custom-control-primary",
                                  attrs: { value: true },
                                },
                                [_vm._v(" 사용 ")]
                              ),
                              _c(
                                "b-form-radio",
                                {
                                  staticClass: "custom-control-secondary",
                                  attrs: { value: false },
                                },
                                [_vm._v(" 사용안함 ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.liveinfo.isTopFix,
                          expression: "liveinfo.isTopFix",
                        },
                      ],
                      attrs: { cols: "12", md: "2" },
                    },
                    [
                      _c(
                        "b-form-group",
                        [
                          _c("validation-provider", {
                            attrs: { name: "노출 영역", rules: "required" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("v-select", {
                                      attrs: {
                                        id: "liveinfo-topFixLevel",
                                        options: _vm.codes.topFixLevel,
                                        reduce: function (option) {
                                          return option.code
                                        },
                                        state: errors.length > 0 ? false : null,
                                        editable: false,
                                        disabled: !_vm.liveinfo.isTopFix,
                                      },
                                      model: {
                                        value: _vm.liveinfo.topFixLevel,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.liveinfo,
                                            "topFixLevel",
                                            $$v
                                          )
                                        },
                                        expression: "liveinfo.topFixLevel",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-card",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.contentType == 1,
              expression: "contentType == 1",
            },
          ],
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-start",
                  attrs: { cols: "12", md: "6" },
                },
                [
                  _c("feather-icon", {
                    attrs: { icon: "RefreshCwIcon", size: "19" },
                  }),
                  _c(
                    "h4",
                    {
                      staticClass: "mb-0 ml-50",
                      staticStyle: { "min-width": "120px" },
                    },
                    [_vm._v(" 라이브 DI ")]
                  ),
                  _c(
                    "b-form-checkbox",
                    {
                      staticClass: "custom-control-info",
                      attrs: { switch: "", inline: "" },
                      model: {
                        value: _vm.liveinfo.isLiveDi,
                        callback: function ($$v) {
                          _vm.$set(_vm.liveinfo, "isLiveDi", $$v)
                        },
                        expression: "liveinfo.isLiveDi",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.liveinfo.isLiveDi ? "사용" : "사용안함") +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "validation-observer",
            { ref: "liveinfoIsLiveDiRules" },
            [
              _vm.liveinfo.isLiveDi
                ? _c(
                    "b-row",
                    { staticClass: "mt-1" },
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "노출 메시지",
                                "label-for": "liveinfo-livedi-title",
                              },
                            },
                            [
                              _c("validation-provider", {
                                attrs: {
                                  name: "노출 메시지",
                                  rules: "required",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("b-form-input", {
                                            attrs: {
                                              id: "liveinfo-livedi-title",
                                              placeholder: "입력하세요",
                                              state:
                                                errors.length > 0
                                                  ? false
                                                  : null,
                                            },
                                            model: {
                                              value: _vm.liveinfo.liveDiTitle,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.liveinfo,
                                                  "liveDiTitle",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "liveinfo.liveDiTitle",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  4129831602
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.liveinfo.isLiveDi
                ? _c(
                    "b-row",
                    { staticClass: "mt-1" },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _c("b-form-group", [
                            _c(
                              "label",
                              { attrs: { for: "liveinfo-livedi-title" } },
                              [_vm._v("노출 구간")]
                            ),
                            _c(
                              "label",
                              { staticClass: "ml-50 text-secondary" },
                              [_vm._v(" (게시기간 : ")]
                            ),
                            _c("label", { staticClass: "ml-50 text-primary" }, [
                              _vm._v(
                                _vm._s(
                                  "" +
                                    (!_vm.liveinfo.startDate
                                      ? "미지정"
                                      : _vm.liveinfo.startDate)
                                )
                              ),
                            ]),
                            _c(
                              "label",
                              { staticClass: "ml-50 text-secondary" },
                              [_vm._v("~")]
                            ),
                            _c("label", { staticClass: "ml-50 text-primary" }, [
                              _vm._v(
                                _vm._s(
                                  " " +
                                    (!_vm.liveinfo.endDate
                                      ? "미지정"
                                      : _vm.liveinfo.endDate)
                                )
                              ),
                            ]),
                            _c("label", { staticClass: "text-secondary" }, [
                              _vm._v(") "),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "7" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c(
                                "b-badge",
                                {
                                  staticClass: "ml-50 mr-50",
                                  staticStyle: { cursor: "pointer" },
                                  attrs: { variant: "light-primary" },
                                  on: { click: _vm.addLiveDiSection },
                                },
                                [
                                  _c("feather-icon", {
                                    staticClass: "mr-25",
                                    attrs: { icon: "PlusIcon" },
                                  }),
                                  _vm._v(" 구간 추가 "),
                                ],
                                1
                              ),
                              _vm.isLiveDiSectionEditable(
                                _vm.liveinfo.liveDiSchedules
                              )
                                ? _c(
                                    "b-badge",
                                    {
                                      staticClass: "ml-50 mr-50",
                                      staticStyle: { cursor: "pointer" },
                                      attrs: { variant: "light-secondary" },
                                      on: {
                                        click: function ($event) {
                                          _vm.showLiveDiSectionSelect = true
                                        },
                                      },
                                    },
                                    [
                                      _c("feather-icon", {
                                        staticClass: "mr-25",
                                        attrs: { icon: "CopyIcon" },
                                      }),
                                      _vm._v(" 구간 가져오기 "),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.liveinfo.isLiveDi
                ? _vm._l(_vm.liveinfo.liveDiSchedules, function (r, idx) {
                    return _c(
                      "b-row",
                      {
                        key: idx,
                        staticStyle: { "margin-top": "5px" },
                        attrs: { id: "livedi-" + idx },
                      },
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "12", md: "3" } },
                          [
                            _c(
                              "b-form-group",
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "알림기간(Fr)",
                                    rules: {
                                      required: true,
                                      before: r.endDate,
                                      dateBetween: [
                                        _vm.liveinfo.startDate,
                                        _vm.liveinfo.endDate,
                                      ],
                                      dateOverlapStart: [
                                        idx,
                                        _vm.liveinfo.liveDiSchedules,
                                      ],
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var errors = ref.errors
                                          var failedRules = ref.failedRules
                                          return [
                                            _c("b-form-datepicker", {
                                              style: _vm.getInputGroupStyle(
                                                "",
                                                r.startDate !== null &&
                                                  _vm.dateDisabled(
                                                    r.startDate,
                                                    r.startDate
                                                  )
                                              ),
                                              attrs: {
                                                size: "sm",
                                                placeholder: "알림기간(Fr)",
                                                readonly:
                                                  r.startDate !== null &&
                                                  _vm.dateDisabled(
                                                    r.startDate,
                                                    r.startDate
                                                  ),
                                                state:
                                                  errors.length > 0
                                                    ? false
                                                    : null,
                                                "date-disabled-fn":
                                                  _vm.dateDisabled,
                                                disabled:
                                                  r.startDate !== null &&
                                                  _vm.dateDisabled(
                                                    r.startDate,
                                                    r.startDate
                                                  ),
                                              },
                                              on: {
                                                id: function ($event) {
                                                  "liveinfo-livedi-start-date-" +
                                                    idx
                                                },
                                              },
                                              model: {
                                                value: r.startDate,
                                                callback: function ($$v) {
                                                  _vm.$set(r, "startDate", $$v)
                                                },
                                                expression: "r.startDate",
                                              },
                                            }),
                                            _c(
                                              "small",
                                              { staticClass: "text-danger" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      failedRules.hasOwnProperty(
                                                        "required"
                                                      )
                                                        ? "필수 입력값입니다."
                                                        : errors[0] +
                                                          failedRules.hasOwnProperty(
                                                            "before"
                                                          )
                                                        ? "구간 종료일보다 늦을 수 없습니다."
                                                        : errors[0] +
                                                          failedRules.hasOwnProperty(
                                                            "dateOverlapStart"
                                                          )
                                                        ? "다른 구간과 중복됩니다."
                                                        : errors[0] +
                                                          failedRules.hasOwnProperty(
                                                            "dateBetween"
                                                          )
                                                        ? "구간은 게시기간 이내로 제한됩니다."
                                                        : errors[0]
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { cols: "12", md: "3" } },
                          [
                            _c(
                              "b-form-group",
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "알림기간(To)",
                                    rules: {
                                      required: true,
                                      after: r.startDate,
                                      dateBetween: [
                                        _vm.liveinfo.startDate,
                                        _vm.liveinfo.endDate,
                                      ],
                                      dateOverlapEnd: [
                                        idx,
                                        _vm.liveinfo.liveDiSchedules,
                                      ],
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var errors = ref.errors
                                          var failedRules = ref.failedRules
                                          return [
                                            _c("b-form-datepicker", {
                                              style: _vm.getInputGroupStyle(
                                                "",
                                                r.startDate !== null &&
                                                  _vm.dateDisabled(
                                                    r.startDate,
                                                    r.startDate
                                                  )
                                              ),
                                              attrs: {
                                                size: "sm",
                                                placeholder: "알림기간(To)",
                                                readonly:
                                                  r.endDate !== null &&
                                                  _vm.dateDisabled(
                                                    r.endDate,
                                                    r.endDate
                                                  ),
                                                state:
                                                  errors.length > 0
                                                    ? false
                                                    : null,
                                                "date-disabled-fn":
                                                  _vm.dateDisabled,
                                                disabled:
                                                  r.startDate !== null &&
                                                  _vm.dateDisabled(
                                                    r.startDate,
                                                    r.startDate
                                                  ),
                                              },
                                              on: {
                                                id: function ($event) {
                                                  "liveinfo-livedi-end-date-" +
                                                    idx
                                                },
                                              },
                                              model: {
                                                value: r.endDate,
                                                callback: function ($$v) {
                                                  _vm.$set(r, "endDate", $$v)
                                                },
                                                expression: "r.endDate",
                                              },
                                            }),
                                            _c(
                                              "small",
                                              { staticClass: "text-danger" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      failedRules.hasOwnProperty(
                                                        "required"
                                                      )
                                                        ? "필수 입력값입니다."
                                                        : errors[0] +
                                                          failedRules.hasOwnProperty(
                                                            "after"
                                                          )
                                                        ? "구간 시작일보다 빠를 수 없습니다."
                                                        : errors[0] +
                                                          failedRules.hasOwnProperty(
                                                            "dateOverlapEnd"
                                                          )
                                                        ? "다른 구간과 중복됩니다."
                                                        : errors[0] +
                                                          failedRules.hasOwnProperty(
                                                            "dateBetween"
                                                          )
                                                        ? "구간은 게시기간 이내로 제한됩니다."
                                                        : errors[0]
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { cols: "12", md: "2" } },
                          [
                            _c("validation-provider", {
                              attrs: {
                                name: "1차",
                                rules: {
                                  required: true,
                                  less: parseInt(r.secondCount),
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c(
                                          "b-input-group",
                                          {
                                            staticClass: "input-group-merge",
                                            attrs: { size: "sm" },
                                          },
                                          [
                                            _c(
                                              "b-input-group-prepend",
                                              [
                                                _c(
                                                  "b-input-group-text",
                                                  {
                                                    staticClass: "form-control",
                                                    style:
                                                      _vm.getInputGroupStyle(
                                                        "sm",
                                                        r.startDate !== null &&
                                                          _vm.dateDisabled(
                                                            r.startDate,
                                                            r.startDate
                                                          )
                                                      ),
                                                    attrs: {
                                                      readonly:
                                                        r.startDate !== null &&
                                                        _vm.dateDisabled(
                                                          r.startDate,
                                                          r.startDate
                                                        ),
                                                    },
                                                  },
                                                  [_vm._v(" 1차 ")]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c("cleave", {
                                              staticClass: "form-control",
                                              staticStyle: {
                                                "text-align": "end",
                                              },
                                              attrs: {
                                                maxlength: "3",
                                                disabled:
                                                  r.startDate !== null &&
                                                  _vm.dateDisabled(
                                                    r.startDate,
                                                    r.startDate
                                                  ),
                                                raw: false,
                                                options: _vm.cleave.number,
                                                placeholder:
                                                  "회차 수를 입력하세요",
                                                state:
                                                  errors.length > 0
                                                    ? false
                                                    : null,
                                              },
                                              on: {
                                                id: function ($event) {
                                                  "liveinfo-livedi-first-count-" +
                                                    idx
                                                },
                                              },
                                              model: {
                                                value: r.firstCount,
                                                callback: function ($$v) {
                                                  _vm.$set(r, "firstCount", $$v)
                                                },
                                                expression: "r.firstCount",
                                              },
                                            }),
                                            _c(
                                              "b-input-group-append",
                                              [
                                                _c(
                                                  "b-input-group-text",
                                                  {
                                                    staticClass: "form-control",
                                                    style:
                                                      _vm.getInputGroupStyle(
                                                        "sm",
                                                        r.startDate !== null &&
                                                          _vm.dateDisabled(
                                                            r.startDate,
                                                            r.startDate
                                                          )
                                                      ),
                                                    attrs: {
                                                      readonly:
                                                        r.startDate !== null &&
                                                        _vm.dateDisabled(
                                                          r.startDate,
                                                          r.startDate
                                                        ),
                                                    },
                                                  },
                                                  [_vm._v(" 회 ")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "small",
                                          { staticClass: "text-danger" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { cols: "12", md: "2" } },
                          [
                            _c("validation-provider", {
                              attrs: {
                                name: "2차",
                                rules: {
                                  required: true,
                                  more: parseInt(r.firstCount),
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c(
                                          "b-input-group",
                                          {
                                            staticClass: "input-group-merge",
                                            attrs: { size: "sm" },
                                          },
                                          [
                                            _c(
                                              "b-input-group-prepend",
                                              [
                                                _c(
                                                  "b-input-group-text",
                                                  {
                                                    staticClass: "form-control",
                                                    style:
                                                      _vm.getInputGroupStyle(
                                                        "sm",
                                                        r.startDate !== null &&
                                                          _vm.dateDisabled(
                                                            r.startDate,
                                                            r.startDate
                                                          )
                                                      ),
                                                    attrs: {
                                                      readonly:
                                                        r.startDate !== null &&
                                                        _vm.dateDisabled(
                                                          r.startDate,
                                                          r.startDate
                                                        ),
                                                    },
                                                  },
                                                  [_vm._v(" 2차 ")]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c("cleave", {
                                              staticClass: "form-control",
                                              staticStyle: {
                                                "text-align": "end",
                                              },
                                              attrs: {
                                                maxlength: "3",
                                                disabled:
                                                  r.startDate !== null &&
                                                  _vm.dateDisabled(
                                                    r.startDate,
                                                    r.startDate
                                                  ),
                                                raw: false,
                                                options: _vm.cleave.number,
                                                placeholder:
                                                  "회차 수를 입력하세요",
                                                state:
                                                  errors.length > 0
                                                    ? false
                                                    : null,
                                              },
                                              on: {
                                                id: function ($event) {
                                                  "liveinfo-livedi-second-count-" +
                                                    idx
                                                },
                                              },
                                              model: {
                                                value: r.secondCount,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    r,
                                                    "secondCount",
                                                    $$v
                                                  )
                                                },
                                                expression: "r.secondCount",
                                              },
                                            }),
                                            _c(
                                              "b-input-group-append",
                                              [
                                                _c(
                                                  "b-input-group-text",
                                                  {
                                                    staticClass:
                                                      "form-control ",
                                                    style:
                                                      _vm.getInputGroupStyle(
                                                        "sm",
                                                        r.startDate !== null &&
                                                          _vm.dateDisabled(
                                                            r.startDate,
                                                            r.startDate
                                                          )
                                                      ),
                                                    attrs: {
                                                      readonly:
                                                        r.startDate !== null &&
                                                        _vm.dateDisabled(
                                                          r.startDate,
                                                          r.startDate
                                                        ),
                                                    },
                                                  },
                                                  [_vm._v(" 회 ")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "small",
                                          { staticClass: "text-danger" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { cols: "12", md: "1" } },
                          [
                            _c(
                              "b-button-toolbar",
                              [
                                _c(
                                  "b-button-group",
                                  { attrs: { size: "sm" } },
                                  [
                                    !(
                                      r.startDate !== null &&
                                      _vm.dateDisabled(r.startDate, r.startDate)
                                    )
                                      ? _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              variant: "flat-danger",
                                              pill: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeLiveDiSection(
                                                  idx
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("feather-icon", {
                                              attrs: { icon: "Trash2Icon" },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  })
                : _vm._e(),
              _vm.liveinfo.isLiveDi
                ? _c(
                    "b-row",
                    { staticClass: "mt-1" },
                    [
                      _c(
                        "b-col",
                        { staticClass: "mt-1", attrs: { cols: "12" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "라이브DI 교차조건",
                                "label-for": "liveinfo-is-livedi-or",
                              },
                            },
                            [
                              _c("b-form-radio-group", {
                                attrs: {
                                  id: "liveinfo-is-livedi-or",
                                  options: _vm.codes.conditionOr,
                                  "value-field": "code",
                                  "text-field": "label",
                                },
                                model: {
                                  value: _vm.liveinfo.isLiveDiOr,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.liveinfo, "isLiveDiOr", $$v)
                                  },
                                  expression: "liveinfo.isLiveDiOr",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("validation-provider", {
                            attrs: {
                              name: "청구코드",
                              rules: {
                                requiredIf:
                                  !_vm.liveinfo.liveDiCondDiseaseCodes &&
                                  !_vm.liveinfo.liveDiCondLabCodes,
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var errors = ref.errors
                                    return [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "청구코드",
                                            "label-for":
                                              "liveinfo-livedi-drug-codes",
                                          },
                                        },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              id: "liveinfo-livedi-drug-codes",
                                              placeholder: "코드를 입력하세요",
                                              state:
                                                errors.length > 0
                                                  ? false
                                                  : null,
                                            },
                                            model: {
                                              value:
                                                _vm.liveinfo
                                                  .liveDiCondDrugCodes,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.liveinfo,
                                                  "liveDiCondDrugCodes",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "liveinfo.liveDiCondDrugCodes",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    errors.length > 0
                                                      ? "라이브 DI 사용시, 조건은 한가지 이상 반드시 입력되어야 합니다."
                                                      : ""
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1097119872
                            ),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("validation-provider", {
                            attrs: {
                              name: "질병코드",
                              rules: {
                                requiredIf:
                                  !_vm.liveinfo.liveDiCondDrugCodes &&
                                  !_vm.liveinfo.liveDiCondLabCodes,
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var errors = ref.errors
                                    return [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "질병코드",
                                            "label-for":
                                              "liveinfo-livedi-disease-codes",
                                          },
                                        },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              id: "liveinfo-livedi-disease-codes",
                                              placeholder: "코드를 입력하세요",
                                              state:
                                                errors.length > 0
                                                  ? false
                                                  : null,
                                            },
                                            model: {
                                              value:
                                                _vm.liveinfo
                                                  .liveDiCondDiseaseCodes,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.liveinfo,
                                                  "liveDiCondDiseaseCodes",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "liveinfo.liveDiCondDiseaseCodes",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    errors.length > 0
                                                      ? "라이브 DI 사용시, 조건은 한가지 이상 반드시 입력되어야 합니다."
                                                      : ""
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1860793072
                            ),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("validation-provider", {
                            attrs: {
                              name: "검사코드",
                              rules: {
                                requiredIf:
                                  !_vm.liveinfo.liveDiCondDrugCodes &&
                                  !_vm.liveinfo.liveDiCondDiseaseCodes,
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var errors = ref.errors
                                    return [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label:
                                              "검사코드 (※ 검사는 원코드 5자리로 입력되어야 합니다. e.g. 'D2280000'[X] 'D2280'[O])",
                                            "label-for":
                                              "liveinfo-livedi-lab-codes",
                                          },
                                        },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              id: "liveinfo-livedi-lab-codes",
                                              placeholder: "코드를 입력하세요",
                                              state:
                                                errors.length > 0
                                                  ? false
                                                  : null,
                                            },
                                            model: {
                                              value:
                                                _vm.liveinfo.liveDiCondLabCodes,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.liveinfo,
                                                  "liveDiCondLabCodes",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "liveinfo.liveDiCondLabCodes",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    errors.length > 0
                                                      ? "라이브 DI 사용시, 조건은 한가지 이상 반드시 입력되어야 합니다."
                                                      : ""
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3358563511
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "b-card",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-start",
                  attrs: { cols: "12", md: "6" },
                },
                [
                  _c("feather-icon", {
                    attrs: { icon: "BellIcon", size: "19" },
                  }),
                  _c(
                    "h4",
                    {
                      staticClass: "mb-0 ml-50",
                      staticStyle: { "min-width": "120px" },
                    },
                    [_vm._v(" 처방 팝업 ")]
                  ),
                  _c(
                    "b-form-checkbox",
                    {
                      staticClass: "custom-control-info",
                      attrs: { switch: "", inline: "" },
                      model: {
                        value: _vm.liveinfo.isInfoPopUp,
                        callback: function ($$v) {
                          _vm.$set(_vm.liveinfo, "isInfoPopUp", $$v)
                        },
                        expression: "liveinfo.isInfoPopUp",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.liveinfo.isInfoPopUp ? "사용" : "사용안함"
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "validation-observer",
            { ref: "liveinfoIsInfoPopRules" },
            [
              _vm.liveinfo.isInfoPopUp
                ? _c(
                    "b-row",
                    { staticClass: "mt-1" },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "알림기간(Fr)",
                                "label-for": "liveinfo-ipu-start-date",
                              },
                            },
                            [
                              _c("validation-provider", {
                                attrs: {
                                  name: "알림기간(Fr)",
                                  rules: {
                                    required: true,
                                    before: _vm.liveinfo.ipuEndDate,
                                    dateBetween: [
                                      _vm.liveinfo.startDate,
                                      _vm.liveinfo.endDate,
                                    ],
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        var failedRules = ref.failedRules
                                        return [
                                          _c("b-form-datepicker", {
                                            attrs: {
                                              id: "liveinfo-ipu-start-date",
                                              placeholder: "알림기간(Fr)",
                                              state:
                                                errors.length > 0
                                                  ? false
                                                  : null,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.spuOneTimeChange()
                                              },
                                            },
                                            model: {
                                              value: _vm.liveinfo.ipuStartDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.liveinfo,
                                                  "ipuStartDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "liveinfo.ipuStartDate",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    failedRules.hasOwnProperty(
                                                      "dateBetween"
                                                    )
                                                      ? "처방 팝업 기간은 게시기간 이내로 제한됩니다."
                                                      : errors[0]
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3185305513
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "알림기간(To)",
                                "label-for": "liveinfo-ipu-end-date",
                              },
                            },
                            [
                              _c("validation-provider", {
                                attrs: {
                                  name: "알림기간(To)",
                                  rules: {
                                    required: true,
                                    after: _vm.liveinfo.ipuStartDate,
                                    dateBetween: [
                                      _vm.liveinfo.startDate,
                                      _vm.liveinfo.endDate,
                                    ],
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        var failedRules = ref.failedRules
                                        return [
                                          _c("b-form-datepicker", {
                                            attrs: {
                                              id: "liveinfo-ipu-end-date",
                                              state:
                                                errors.length > 0
                                                  ? false
                                                  : null,
                                              placeholder: "알림기간(To))",
                                            },
                                            model: {
                                              value: _vm.liveinfo.ipuEndDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.liveinfo,
                                                  "ipuEndDate",
                                                  $$v
                                                )
                                              },
                                              expression: "liveinfo.ipuEndDate",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    failedRules.hasOwnProperty(
                                                      "dateBetween"
                                                    )
                                                      ? "처방 팝업 기간은 게시기간 이내로 제한됩니다."
                                                      : errors[0]
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  182840837
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("b-col", { attrs: { cols: "12", md: "6" } }),
                      _c(
                        "b-col",
                        { attrs: { cols: "6", md: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "처방팝업 알림 시간",
                                "label-for": "liveinfo-is-immediate",
                              },
                            },
                            [
                              _c("b-form-radio-group", {
                                attrs: {
                                  id: "liveinfo-is-immediate",
                                  options: _vm.codes.immediateOr,
                                  "value-field": "code",
                                  "text-field": "label",
                                },
                                model: {
                                  value: _vm.liveinfo.isImmediate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.liveinfo, "isImmediate", $$v)
                                  },
                                  expression: "liveinfo.isImmediate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "6", md: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "알림횟수",
                                "label-for": "liveinfo-ipu-pop-count",
                              },
                            },
                            [
                              _c("b-form-spinbutton", {
                                attrs: {
                                  id: "liveinfo-ipu-pop-count",
                                  min: "1",
                                  max: "10",
                                  inline: "",
                                },
                                model: {
                                  value: _vm.liveinfo.ipuPopCount,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.liveinfo, "ipuPopCount", $$v)
                                  },
                                  expression: "liveinfo.ipuPopCount",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "6", md: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "알림 유지시간",
                                "label-for": "liveinfo-ipu-delay-sec",
                              },
                            },
                            [
                              _c("validation-provider", {
                                attrs: {
                                  name: "알림 유지시간",
                                  rules: "required",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-select", {
                                            attrs: {
                                              id: "liveinfo-ipu-delay-sec",
                                              options: _vm.codes.selectTimes,
                                              reduce: function (option) {
                                                return option.code
                                              },
                                              state:
                                                errors.length > 0
                                                  ? false
                                                  : null,
                                            },
                                            model: {
                                              value: _vm.liveinfo.ipuDelaySec,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.liveinfo,
                                                  "ipuDelaySec",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "liveinfo.ipuDelaySec",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2952924992
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("b-col", { attrs: { cols: "6", md: "3" } }),
                      !_vm.liveinfo.isImmediate
                        ? _c(
                            "div",
                            [
                              _c("b-col", { attrs: { cols: "12" } }, [
                                _c("label", [_vm._v("알림 시간 선택")]),
                              ]),
                              _vm._l(
                                _vm.liveinfo.schedules,
                                function (sched, idx) {
                                  return _c(
                                    "b-col",
                                    {
                                      key: sched.id,
                                      staticClass:
                                        "d-flex align-items-center justify-content-start",
                                      class: { "mt-25": idx !== 0 },
                                      attrs: {
                                        id: "schedules" + sched.id,
                                        cols: "12",
                                      },
                                    },
                                    [
                                      sched.popUpType === 1
                                        ? [
                                            _c("validation-provider", {
                                              attrs: {
                                                name: "알림 시간 선택",
                                                rules: "required",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (ref) {
                                                      var errors = ref.errors
                                                      return [
                                                        _c("v-date-picker", {
                                                          staticClass: "mr-1",
                                                          attrs: {
                                                            type: "time",
                                                            format: "HH:mm",
                                                            "value-type":
                                                              "format",
                                                            placeholder:
                                                              "HH:mm",
                                                            "time-picker-options":
                                                              {
                                                                start: "08:00",
                                                                step: "00:05",
                                                                end: "20:00",
                                                              },
                                                          },
                                                          model: {
                                                            value:
                                                              sched.noticeTime,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                sched,
                                                                "noticeTime",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "sched.noticeTime",
                                                          },
                                                        }),
                                                        _c(
                                                          "small",
                                                          {
                                                            staticClass:
                                                              "text-danger",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(errors[0])
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                            idx !==
                                            _vm.getSchedIdxFirstOrLast(1, true)
                                              ? _c(
                                                  "b-button",
                                                  {
                                                    staticClass: "mr-1",
                                                    attrs: {
                                                      variant: "outline-danger",
                                                      size: "sm",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.liveinfo.schedules.splice(
                                                          idx,
                                                          1
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("feather-icon", {
                                                      staticClass: "mr-25",
                                                      attrs: { icon: "XIcon" },
                                                    }),
                                                    _c("span", [
                                                      _vm._v("삭제"),
                                                    ]),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            idx ===
                                            _vm.getSchedIdxFirstOrLast(1, false)
                                              ? _c(
                                                  "b-button",
                                                  {
                                                    attrs: {
                                                      variant:
                                                        "outline-primary",
                                                      size: "sm",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.liveinfo.schedules.push(
                                                          {
                                                            noticeTime: null,
                                                            popUpType: 1,
                                                          }
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("feather-icon", {
                                                      staticClass: "mr-25",
                                                      attrs: {
                                                        icon: "PlusIcon",
                                                      },
                                                    }),
                                                    _c("span", [
                                                      _vm._v("추가"),
                                                    ]),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                }
                              ),
                            ],
                            2
                          )
                        : _vm._e(),
                      _c(
                        "b-col",
                        { staticClass: "mt-1", attrs: { cols: "12" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "팝업 교차조건",
                                "label-for": "liveinfo-is-info-popup-or",
                              },
                            },
                            [
                              _c("b-form-radio-group", {
                                attrs: {
                                  id: "liveinfo-is-info-popup-or",
                                  options: _vm.codes.conditionOr,
                                  "value-field": "code",
                                  "text-field": "label",
                                },
                                model: {
                                  value: _vm.liveinfo.isInfoPopUpOr,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.liveinfo, "isInfoPopUpOr", $$v)
                                  },
                                  expression: "liveinfo.isInfoPopUpOr",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("validation-provider", {
                            attrs: {
                              name: "청구코드",
                              rules: {
                                requiredIf:
                                  !_vm.liveinfo.condDiseaseCodes &&
                                  !_vm.liveinfo.condLabCodes,
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var errors = ref.errors
                                    return [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "청구코드",
                                            "label-for": "liveinfo-drug-codes",
                                          },
                                        },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              id: "liveinfo-drug-codes",
                                              placeholder: "코드를 입력하세요",
                                              state:
                                                errors.length > 0
                                                  ? false
                                                  : null,
                                            },
                                            model: {
                                              value: _vm.liveinfo.condDrugCodes,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.liveinfo,
                                                  "condDrugCodes",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "liveinfo.condDrugCodes",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    errors.length > 0
                                                      ? "처방팝업 사용시, 조건은 한가지 이상 반드시 입력되어야 합니다."
                                                      : ""
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3992123803
                            ),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("validation-provider", {
                            attrs: {
                              name: "질병코드",
                              rules: {
                                requiredIf:
                                  !_vm.liveinfo.condDrugCodes &&
                                  !_vm.liveinfo.condLabCodes,
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var errors = ref.errors
                                    return [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "질병코드",
                                            "label-for":
                                              "liveinfo-disease-codes",
                                          },
                                        },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              id: "liveinfo-disease-codes",
                                              placeholder: "코드를 입력하세요",
                                              state:
                                                errors.length > 0
                                                  ? false
                                                  : null,
                                            },
                                            model: {
                                              value:
                                                _vm.liveinfo.condDiseaseCodes,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.liveinfo,
                                                  "condDiseaseCodes",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "liveinfo.condDiseaseCodes",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    errors.length > 0
                                                      ? "처방팝업 사용시, 조건은 한가지 이상 반드시 입력되어야 합니다."
                                                      : ""
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1213708875
                            ),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("validation-provider", {
                            attrs: {
                              name: "검사코드",
                              rules: {
                                requiredIf:
                                  !_vm.liveinfo.condDrugCodes &&
                                  !_vm.liveinfo.condDiseaseCodes,
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var errors = ref.errors
                                    return [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label:
                                              "검사코드 (※ 검사는 원코드 5자리로 입력되어야 합니다. e.g. 'D2280000'[X] 'D2280'[O])",
                                            "label-for": "liveinfo-lab-codes",
                                          },
                                        },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              id: "liveinfo-lab-codes",
                                              placeholder: "코드를 입력하세요",
                                              state:
                                                errors.length > 0
                                                  ? false
                                                  : null,
                                            },
                                            model: {
                                              value: _vm.liveinfo.condLabCodes,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.liveinfo,
                                                  "condLabCodes",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "liveinfo.condLabCodes",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    errors.length > 0
                                                      ? "처방팝업 사용시, 조건은 한가지 이상 반드시 입력되어야 합니다."
                                                      : ""
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              321577708
                            ),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label:
                                  "알림창 내용 (※ 처방팝업과 스케줄팝업 문구는 반드시 다르게 설정하여 주세요.)",
                                "label-for": "liveinfo-ipu-content",
                              },
                            },
                            [
                              _c("validation-provider", {
                                attrs: {
                                  name: "알림창 내용",
                                  rules: "required|popupContent",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("tinymce-editor", {
                                            attrs: { "is-table": true },
                                            model: {
                                              value: _vm.liveinfo.ipuContent,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.liveinfo,
                                                  "ipuContent",
                                                  $$v
                                                )
                                              },
                                              expression: "liveinfo.ipuContent",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  468789798
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-card",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-start",
                  attrs: { cols: "12", md: "6" },
                },
                [
                  _c("feather-icon", {
                    attrs: { icon: "ClockIcon", size: "19" },
                  }),
                  _c(
                    "h4",
                    {
                      staticClass: "mb-0 ml-50",
                      staticStyle: { "min-width": "120px" },
                    },
                    [_vm._v(" 스케줄 팝업 ")]
                  ),
                  _c(
                    "b-form-checkbox",
                    {
                      staticClass: "custom-control-info",
                      attrs: { switch: "", inline: "" },
                      model: {
                        value: _vm.liveinfo.isScheduleUse,
                        callback: function ($$v) {
                          _vm.$set(_vm.liveinfo, "isScheduleUse", $$v)
                        },
                        expression: "liveinfo.isScheduleUse",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.liveinfo.isScheduleUse ? "사용" : "사용안함"
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "validation-observer",
            { ref: "liveinfoIsScheduleRules" },
            [
              _vm.liveinfo.isScheduleUse
                ? _c(
                    "b-row",
                    { staticClass: "mt-1" },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "알림기간(Fr)",
                                "label-for": "liveinfo-spu-start-date",
                              },
                            },
                            [
                              _c("validation-provider", {
                                attrs: {
                                  name: "알림기간(Fr)",
                                  rules: {
                                    required: true,
                                    before: _vm.liveinfo.spuEndDate,
                                    dateBetween: [
                                      _vm.liveinfo.startDate,
                                      _vm.liveinfo.endDate,
                                    ],
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        var failedRules = ref.failedRules
                                        return [
                                          _c("b-form-datepicker", {
                                            attrs: {
                                              id: "liveinfo-spu-start-date",
                                              state:
                                                errors.length > 0
                                                  ? false
                                                  : null,
                                            },
                                            model: {
                                              value: _vm.liveinfo.spuStartDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.liveinfo,
                                                  "spuStartDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "liveinfo.spuStartDate",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    failedRules.hasOwnProperty(
                                                      "dateBetween"
                                                    )
                                                      ? "스케줄 팝업 기간은 게시기간 이내로 제한됩니다."
                                                      : errors[0]
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  4126294070
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      !_vm.liveinfo.isSpuOneTime
                        ? _c(
                            "b-col",
                            { attrs: { cols: "12", md: "3" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "알림기간(To)",
                                    "label-for": "liveinfo-spu-end-date",
                                  },
                                },
                                [
                                  _c("validation-provider", {
                                    attrs: {
                                      name: "알림기간(To)",
                                      rules: {
                                        required: true,
                                        after: _vm.liveinfo.spuStartDate,
                                        dateBetween: [
                                          _vm.liveinfo.startDate,
                                          _vm.liveinfo.endDate,
                                        ],
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var errors = ref.errors
                                            var failedRules = ref.failedRules
                                            return [
                                              _c("b-form-datepicker", {
                                                attrs: {
                                                  id: "liveinfo-spu-end-date",
                                                  state:
                                                    errors.length > 0
                                                      ? false
                                                      : null,
                                                },
                                                model: {
                                                  value:
                                                    _vm.liveinfo.spuEndDate,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.liveinfo,
                                                      "spuEndDate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "liveinfo.spuEndDate",
                                                },
                                              }),
                                              _c(
                                                "small",
                                                { staticClass: "text-danger" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        failedRules.hasOwnProperty(
                                                          "dateBetween"
                                                        )
                                                          ? "스케줄 팝업 기간은 게시기간 이내로 제한됩니다."
                                                          : errors[0]
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      201237686
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("b-col", { attrs: { cols: "12", md: "6" } }),
                      _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "알림 유지시간",
                                "label-for": "liveinfo-spu-delay-sec",
                              },
                            },
                            [
                              _c("validation-provider", {
                                attrs: {
                                  name: "알림 유지시간",
                                  rules: "required",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-select", {
                                            staticStyle: { width: "210px" },
                                            attrs: {
                                              id: "liveinfo-spu-delay-sec",
                                              options: _vm.codes.selectTimes,
                                              reduce: function (option) {
                                                return option.code
                                              },
                                              state:
                                                errors.length > 0
                                                  ? false
                                                  : null,
                                            },
                                            model: {
                                              value: _vm.liveinfo.spuDelaySec,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.liveinfo,
                                                  "spuDelaySec",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "liveinfo.spuDelaySec",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3944420312
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("b-col", { attrs: { cols: "12" } }, [
                        _c("label", [_vm._v("알림 시간 선택")]),
                      ]),
                      _vm._l(_vm.liveinfo.schedules, function (sched, idx) {
                        return _c(
                          "b-col",
                          {
                            key: sched.id,
                            staticClass:
                              "d-flex align-items-center justify-content-start",
                            class: { "mt-25": idx !== 0 },
                            attrs: { id: "schedules" + sched.id, cols: "12" },
                          },
                          [
                            sched.popUpType === 2
                              ? [
                                  _c("validation-provider", {
                                    attrs: {
                                      name: "알림 시간 선택",
                                      rules: "required",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var errors = ref.errors
                                            return [
                                              _c("v-date-picker", {
                                                staticClass: "mr-1",
                                                attrs: {
                                                  type: "time",
                                                  format: "HH:mm",
                                                  "value-type": "format",
                                                  placeholder: "HH:mm",
                                                  "time-picker-options": {
                                                    start: "08:00",
                                                    step: "00:05",
                                                    end: "20:00",
                                                  },
                                                },
                                                model: {
                                                  value: sched.noticeTime,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      sched,
                                                      "noticeTime",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "sched.noticeTime",
                                                },
                                              }),
                                              _c(
                                                "small",
                                                { staticClass: "text-danger" },
                                                [_vm._v(_vm._s(errors[0]))]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  idx !== _vm.getSchedIdxFirstOrLast(2, true)
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass: "mr-1",
                                          attrs: {
                                            variant: "outline-danger",
                                            size: "sm",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.liveinfo.schedules.splice(
                                                idx,
                                                1
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("feather-icon", {
                                            staticClass: "mr-25",
                                            attrs: { icon: "XIcon" },
                                          }),
                                          _c("span", [_vm._v("삭제")]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  idx === _vm.getSchedIdxFirstOrLast(2, false)
                                    ? _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            variant: "outline-primary",
                                            size: "sm",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.liveinfo.schedules.push(
                                                {
                                                  noticeTime: null,
                                                  popUpType: 2,
                                                }
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("feather-icon", {
                                            staticClass: "mr-25",
                                            attrs: { icon: "PlusIcon" },
                                          }),
                                          _c("span", [_vm._v("추가")]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              : _vm._e(),
                          ],
                          2
                        )
                      }),
                      _c(
                        "b-col",
                        { staticClass: "mt-1", attrs: { cols: "12" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label:
                                  "알림창 내용 (※ 처방팝업과 스케줄팝업 문구는 반드시 다르게 설정하여 주세요.)",
                                "label-for": "liveinfo-spu-content",
                              },
                            },
                            [
                              _c("validation-provider", {
                                attrs: {
                                  name: "알림창 내용",
                                  rules: "required|popupContent",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("tinymce-editor", {
                                            attrs: { "is-table": true },
                                            model: {
                                              value: _vm.liveinfo.spuContent,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.liveinfo,
                                                  "spuContent",
                                                  $$v
                                                )
                                              },
                                              expression: "liveinfo.spuContent",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1013017084
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("major-select", {
        attrs: {
          "show-major-select": _vm.showMajorSelect,
          "selected-value": _vm.liveinfo.condMajorCodes,
        },
        on: { close: _vm.majorSelect.close },
      }),
      _c("region-select", {
        attrs: {
          "show-region-select": _vm.showRegionSelect,
          "selected-value": _vm.liveinfo.condRegionCodes,
        },
        on: { close: _vm.regionSelect.close },
      }),
      _c("medi-dept-select", {
        attrs: {
          "show-medi-dept-select": _vm.showMediDeptSelect,
          "selected-value": _vm.liveinfo.condDeptCodes,
        },
        on: { close: _vm.mediDeptSelect.close },
      }),
      _c("live-di-section-select", {
        attrs: { "show-live-di-section-select": _vm.showLiveDiSectionSelect },
        on: { close: _vm.liveDiSectionSelect.close },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }