<!-- [팝업] 라이브DI 검색 -->
<template>
  <b-modal
    v-model="showLiveDiSectionSelect"
    cancel-variant="outline-secondary"
    centered
    ok-title="적용"
    cancel-title="취소"
    size="lg"
    title="라이브DI 검색"
    no-close-on-esc
    no-close-on-backdrop
    @ok.prevent="submit()"
    @close.prevent="close()"
    @cancel.prevent="close()"
  >
    <b-row
      class="mt-25"
    >
      <b-col
        cols="12"
        md="10"
        class="d-flex align-items-center justify-content-center"
      >
        <b-form-input
          v-model="query.filterStr"
          class="el-def"
          placeholder="검색어를 입력하세요."
          @keyup.enter="refetchData()"
        />
      </b-col>
      <!-- Search -->
      <b-col
        cols="12"
        md="2"
        class="d-flex align-items-center justify-content-end"
      >
        <b-button
          variant="primary"
          class="btn-search"
          @click.prevent="refetchData()"
        >
          조회
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-table
        ref="refLiveDiTable"
        primary-key="id"
        :items="fetchLiveDis"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDesc"
        responsive
        sticky-header
        selectable
        select-mode="single"
        class="position-relative"
        @row-selected="onRowSelectedLiveDi"
        @row-dblclicked="onRowDblClickedLiveDi"
      >
        <template #cell(createDate)="data">
          <span class="text-nowrap">
            {{ data.value | $dateFormatter('YYYY-MM-DD') }}
          </span>
        </template>
        <template #cell(modifyDate)="data">
          <span class="text-nowrap">
            {{ data.value | $dateFormatter('YYYY-MM-DD') }}
          </span>
        </template>
      </b-table>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-pagination
          v-model="currPage"
          :total-rows="totalRecords"
          limit="10"
          per-page="10"
          align="center"
        />
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import axios from '@axios'
import { ref, watch, onMounted } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { errorFormatter } from '@core/utils/filter'

export default {
  components: {
  },

  props: {
    showLiveDiSectionSelect: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, { emit }) {
    onMounted(() => {
      // fetchCodes()
    })

    const toast = useToast()

    // Query Data
    const query = ref({
      filterStr: null,
    })
    // Query Data End.

    const liveDiData = ref(null)

    // Main Table Config
    const refLiveDiTable = ref(null)
    // const sortBy = ref('modifyDate')
    const sortBy = ref('liveInfoId')
    const isSortDesc = ref(true)
    const currPage = ref(1)
    const totalRecords = ref(0)
    const tableColumns = [
      // { key: 'id', label: 'Id', sortable: true },
      {
        key: 'liveInfoId', label: 'Id', sortable: true, thStyle: { width: '10%' },
      },
      {
        key: 'client.clientName', label: '제약사', sortable: true, thStyle: { width: '20%' },
      },
      { key: 'liveInfo.title', label: '약품정보 제목', sortable: true },
      // { key: 'liveInfo.liveDiTitle', label: '라이브DI 노출메시지', sortable: true },
      // { key: 'createDate', label: '등록일', sortable: true },
      // { key: 'modifyDate', label: '최종 수정일', sortable: true },
    ]
    const refetchData = () => {
      refLiveDiTable.value.refresh()
    }
    watch([currPage], () => {
      refetchData()
    })
    const onRowSelectedLiveDi = items => {
      const item = items[0]
      if (item) {
        liveDiData.value = item
      }
    }
    const onRowDblClickedLiveDi = item => {
      if (item) {
        liveDiData.value = item
      }
      submit()
    }
    // Main Table Config End.

    const fetchLiveDis = (ctx, callback) => {
      axios
        .post("/fa/druginfo/quick-search/live-di", {
          search: {
            filterStr: query.value.filterStr,
          },
          sort: {
            predicate: sortBy.value,
            reverse: isSortDesc.value,
          },
          pagination: {
            number: currPage.value,
          },
        })
        .then(rs => {
          const { items, totalRecord } = rs.data
          callback(items)
          totalRecords.value = totalRecord
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: errorFormatter(error, '라이브DI 데이터 조회에 실패하였습니다.'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const close = () => {
      emit('close', null)
    }

    const submit = () => {
      if (liveDiData.value === null) {
        toast({
          component: ToastificationContent,
          props: {
            title: '라이브DI를 선택하세요',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
        return
      }

      emit('close', liveDiData.value.liveInfo.liveDiSchedules)
    }

    return {
      query,
      close,
      submit,
      refLiveDiTable,
      sortBy,
      currPage,
      isSortDesc,
      totalRecords,
      tableColumns,
      refetchData,
      onRowSelectedLiveDi,
      onRowDblClickedLiveDi,
      fetchLiveDis,
    }
  },
}
</script>
